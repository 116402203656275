<template>
  <div class="row">
    <div class="col-lg-12 mb-5">
      <h5 class="mb-4">
        <strong>Default Dropdowns</strong>
      </h5>
      <b-dropdown variant="clear" text="Clear" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="default" text="Default" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="primary" text="Primary" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="secondary" text="Secondary" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="success" text="Success" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="info" text="Info" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="warning" text="Warning" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="danger" text="Danger" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="light" text="Light" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="dark" text="Dark" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <br />
      <b-dropdown variant="outline-default" text="Default" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-primary" text="Primary" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-secondary" text="Secondary" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-success" text="Success" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-info" text="Info" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-warning" text="Warning" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-danger" text="Danger" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-light" text="Light" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown variant="outline-dark" text="Dark" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Alignment</strong>
      </h5>
      <b-dropdown variant="outline-default" text="Right Aligned" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Dividers</strong>
      </h5>
      <b-dropdown variant="outline-default" text="With dividers" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Headers</strong>
      </h5>
      <b-dropdown variant="outline-default" text="With headers" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-header>Header</b-dropdown-header>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Icons</strong>
      </h5>
      <b-dropdown variant="outline-default" text="With icons" class="mb-3 mr-2">
        <b-dropdown-item>
          <i class="fe fe-activity mr-1"></i>
          Reply
        </b-dropdown-item>
        <b-dropdown-item>
          <i class="fe fe-clock mr-1"></i>Share
        </b-dropdown-item>
        <b-dropdown-item>
          <i class="fe fe-credit-card mr-1"></i>Delete
        </b-dropdown-item>
        <b-dropdown-item>
          <i class="fe fe-plus-circle mr-1"></i>Add
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>
          <i class="fe fe-settings mr-1"></i>Settings
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Active Item</strong>
      </h5>

      <b-dropdown variant="outline-default" text="With active item" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item active>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Disabled Item</strong>
      </h5>
      <b-dropdown variant="outline-default" text="With disabled item" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item disabled>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Sizing</strong>
      </h5>
      <b-dropdown size="lg" variant="outline-default" text="Large" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
      <b-dropdown size="sm" variant="outline-default" text="Small" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Split Buttons</strong>
      </h5>
      <b-dropdown variant="outline-default" split text="Split me" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-lg-4 mb-5">
      <h5 class="mb-4">
        <strong>Dropup</strong>
      </h5>
      <b-dropdown dropup variant="outline-default" text="Right Aligned" class="mb-3 mr-2">
        <b-dropdown-header>Header</b-dropdown-header>
        <b-dropdown-item disabled>Action</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
        <b-dropdown-item>Another Action</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>Something else is here</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitBootstrapDropdownsExample',
}
</script>
