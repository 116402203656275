<template>
  <div class="row">
    <div class="col-lg-6 mb-5">
      <h5 class="mb-4">
        <strong>Default Buttons</strong>
      </h5>
      <b-button variant="clear" class="mr-2 mb-2">Clear</b-button>
      <b-button variant="default" class="mr-2 mb-2">Default</b-button>
      <b-button variant="default" class="mr-2 mb-2" disabled>Default Disabled</b-button>
      <b-button variant="link" class="mr-2 mb-2">Link</b-button>
      <br />
      <b-button variant="primary" class="mr-2 mb-2">Primary</b-button>
      <b-button variant="secondary" class="mr-2 mb-2">Secondary</b-button>
      <b-button variant="success" class="mr-2 mb-2">Success</b-button>
      <b-button variant="danger" class="mr-2 mb-2">Danger</b-button>
      <b-button variant="warning" class="mr-2 mb-2">Warning</b-button>
      <b-button variant="info" class="mr-2 mb-2">Info</b-button>
      <b-button variant="light" class="mr-2 mb-2">Light</b-button>
      <b-button variant="dark" class="mr-2 mb-2">Dark</b-button>
      <br />
      <b-button variant="outline-info" class="mr-2 mb-2">Info</b-button>
      <b-button variant="outline-light" class="mr-2 mb-2">Light</b-button>
      <b-button variant="outline-dark" class="mr-2 mb-2">Dark</b-button>
      <b-button variant="outline-primary" class="mr-2 mb-2">Primary</b-button>
      <b-button variant="outline-secondary" class="mr-2 mb-2">Secondary</b-button>
      <b-button variant="outline-success" class="mr-2 mb-2">Success</b-button>
      <b-button variant="outline-danger" class="mr-2 mb-2">Danger</b-button>
      <b-button variant="outline-warning" class="mr-2 mb-2">Warning</b-button>
    </div>
    <div class="col-lg-6 mb-5">
      <h5 class="mb-4">
        <strong>Sizing</strong>
      </h5>
      <b-button variant="clear" class="mr-2 mb-2">Normal</b-button>
      <b-button variant="default" class="mr-2 mb-2">Normal</b-button>
      <b-button variant="default" class="mr-2 mb-2" disabled>Normal</b-button>
      <a href="javascript: void(0);" class="btn-link mr-2 mb-2">Normal</a>
      <br />
      <b-button variant="primary" size="lg" class="mr-2 mb-2">Large</b-button>
      <b-button variant="secondary" class="btn-secondary mr-2 mb-2">Normal</b-button>
      <b-button variant="success" size="lg" class="btn-lg btn-success mr-2 mb-2">Large</b-button>
      <b-button variant="warning" class="btn-warning mr-2 mb-2">Normal</b-button>
      <b-button variant="danger" size="sm" class="btn-sm btn-danger mr-2 mb-2">Small</b-button>
      <br />
      <b-button variant="outline-info" class="mr-2 mb-2">Noraml</b-button>
      <b-button variant="outline-success" size="sm" class="mr-2 mb-2">Small</b-button>
      <b-button variant="outline-primary" size="lg" class="mr-2 mb-2">Large</b-button>
      <b-button variant="outline-danger" class="btn-outline-danger mr-2 mb-2">Normal</b-button>
    </div>
    <div class="col-lg-6 mb-5">
      <h5 class="mb-4">
        <strong>Squared</strong>
      </h5>
      <b-button variant="clear" squared class="mr-2 mb-2">Clear</b-button>
      <b-button squared variant="default" class="mr-2 mb-2">Default</b-button>
      <b-button squared variant="default" class="mr-2 mb-2" disabled>Default Disabled</b-button>
      <b-button squared variant="link" class="mr-2 mb-2">Link</b-button>
      <br />
      <b-button squared variant="primary" class="mr-2 mb-2">Primary</b-button>
      <b-button squared variant="secondary" class="mr-2 mb-2">Secondary</b-button>
      <b-button squared variant="warning" class="mr-2 mb-2">Warning</b-button>
      <b-button squared variant="info" class="mr-2 mb-2">Info</b-button>
      <b-button squared variant="light" class="mr-2 mb-2">Light</b-button>
      <b-button squared variant="dark" class="mr-2 mb-2">Dark</b-button>
      <b-button squared variant="success" class="mr-2 mb-2">Success</b-button>
      <b-button squared variant="danger" class="mr-2 mb-2">Danger</b-button>
      <br />
      <b-button squared variant="outline-info" class="mr-2 mb-2">Info</b-button>
      <b-button squared variant="outline-light" class="mr-2 mb-2">Light</b-button>
      <b-button squared variant="outline-dark" class="mr-2 mb-2">Dark</b-button>
      <b-button squared variant="outline-primary" class="mr-2 mb-2">Primary</b-button>
      <b-button squared variant="outline-secondary" class="mr-2 mb-2">Secondary</b-button>
      <b-button squared variant="outline-success" class="mr-2 mb-2">Success</b-button>
      <b-button squared variant="outline-danger" class="mr-2 mb-2">Danger</b-button>
      <b-button squared variant="outline-warning" class="mr-2 mb-2">Warning</b-button>
    </div>
    <div class="col-lg-6 mb-5">
      <h5 class="mb-4">
        <strong>Rounded</strong>
      </h5>
      <b-button pill variant="clear" class="mr-2 mb-2">Clear</b-button>
      <b-button pill variant="default" class="mr-2 mb-2">Default</b-button>
      <b-button pill variant="default" class="mr-2 mb-2" disabled>Default Disabled</b-button>
      <b-button pill variant="link" class="mr-2 mb-2">Link</b-button>
      <br />
      <b-button pill variant="danger" class="mr-2 mb-2">Danger</b-button>
      <b-button pill variant="warning" class="mr-2 mb-2">Warning</b-button>
      <b-button pill variant="info" class="mr-2 mb-2">Info</b-button>
      <b-button pill variant="light" class="mr-2 mb-2">Light</b-button>
      <b-button pill variant="dark" class="mr-2 mb-2">Dark</b-button>
      <b-button pill variant="primary" class="mr-2 mb-2">Primary</b-button>
      <b-button pill variant="secondary" class="mr-2 mb-2">Secondary</b-button>
      <b-button pill variant="success" class="mr-2 mb-2">Success</b-button>
      <br />
      <b-button pill variant="outline-info" class="mr-2 mb-2">Info</b-button>
      <b-button pill variant="outline-light" class="mr-2 mb-2">Light</b-button>
      <b-button pill variant="outline-dark" class="mr-2 mb-2">Dark</b-button>
      <b-button pill variant="outline-primary" class="mr-2 mb-2">Primary</b-button>
      <b-button pill variant="outline-secondary" class="mr-2 mb-2">Secondary</b-button>
      <b-button pill variant="outline-success" class="mr-2 mb-2">Success</b-button>
      <b-button pill variant="outline-danger" class="mr-2 mb-2">Danger</b-button>
      <b-button pill variant="outline-warning" class="mr-2 mb-2">Warning</b-button>
    </div>
    <div class="col-lg-6 mb-5">
      <h5 class="mb-4">
        <strong>Icon Buttons</strong>
      </h5>
      <div class="btn-group mr-2 mb-2" aria-label role="group">
        <b-button variant="success">
          <i class="fe fe-edit mr-1" aria-hidden="true"></i>
          Edit
        </b-button>
        <b-button variant="success">
          <i class="fe fe-send mr-1" aria-hidden="true"></i>
          Reply
        </b-button>
        <b-button variant="success">
          <i class="fe fe-share mr-1" aria-hidden="true"></i>
          Share
        </b-button>
      </div>
      <div class="btn-group mr-2 mb-2" aria-label role="group">
        <b-button variant="light">
          <i class="fe fe-edit mr-1" aria-hidden="true"></i>
          Edit
        </b-button>
        <b-button variant="light">
          <i class="fe fe-send mr-1" aria-hidden="true"></i>
          Reply
        </b-button>
        <b-button variant="light">
          <i class="fe fe-share mr-1" aria-hidden="true"></i>
          Share
        </b-button>
      </div>
      <br />
      <b-button variant="clear" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-activity"></i>
      </b-button>
      <b-button variant="secondary" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-alert-triangle"></i>
      </b-button>
      <b-button variant="success" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-anchor"></i>
      </b-button>
      <b-button variant="danger" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-award"></i>
      </b-button>
      <b-button variant="dark" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-battery"></i>
      </b-button>
      <b-button variant="warning" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-clock"></i>
      </b-button>
      <b-button variant="info" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-code"></i>
      </b-button>
      <b-button variant="light" class="btn-rounded mr-2 mb-2">
        <i class="fe fe-credit-card"></i>
      </b-button>
    </div>
    <div class="col-lg-4 mb-3">
      <h5 class="mb-4">
        <strong>Block Buttons</strong>
      </h5>
      <b-button variant="primary" class="btn-rounded btn-block mr-2 mb-2">
        <i class="fe fe-save mr-1"></i>
        Primary
      </b-button>
      <b-button variant="success" class="btn-rounded btn-block mr-2 mb-2">Success</b-button>
      <b-button variant="clear" class="btn-rounded btn-block mr-2 mb-2">Clear</b-button>
    </div>
  </div>
  <!-- <div>
    <b-button class="mr-2">Button</b-button>
    <b-button class="mr-2" variant="danger">Button</b-button>
    <b-button class="mr-2" variant="success">Button</b-button>
    <b-button class="mr-2" variant="outline-primary">Button</b-button>
  </div>-->
</template>
<script>
export default {
  name: 'KitBootstrapButtonsExample',
}
</script>
