<template>
  <div class="row">
    <div class="col-lg-6">
      <h5 class="mb-4">
        <strong>Base</strong>
      </h5>
      <div class="mb-5">
        <h1>
          Example heading
          <b-badge variant="light">New</b-badge>
        </h1>
        <h2>
          Example heading
          <b-badge variant="light">New</b-badge>
        </h2>
        <h3>
          Example heading
          <b-badge variant="light">New</b-badge>
        </h3>
        <h4>
          Example heading
          <b-badge variant="light">New</b-badge>
        </h4>
        <h5>
          Example heading
          <b-badge variant="light">New</b-badge>
        </h5>
        <h6>
          Example heading
          <b-badge variant="light">New</b-badge>
        </h6>
      </div>
    </div>
    <div class="col-lg-6">
      <h5 class="mb-4">
        <strong>In Buttons</strong>
      </h5>
      <div class="mb-5">
        <button class="btn btn-primary">
          Notifications
          <b-badge variant="light">4</b-badge>
        </button>
      </div>
    </div>
    <div class="col-lg-6">
      <h5 class="mb-4">
        <strong>Colors</strong>
      </h5>
      <div class="mb-5">
        <b-badge class="mr-2" variant="primary">Primary</b-badge>
        <b-badge class="mr-2" variant="secondary">Secondary</b-badge>
        <b-badge class="mr-2" variant="success">Success</b-badge>
        <b-badge class="mr-2" variant="danger">Danger</b-badge>
        <b-badge class="mr-2" variant="warning">Warning</b-badge>
        <b-badge class="mr-2" variant="info">Info</b-badge>
        <b-badge class="mr-2" variant="light">Light</b-badge>
        <b-badge class="mr-2" variant="dark">Dark</b-badge>
      </div>
    </div>
    <div class="col-lg-6">
      <h5 class="mb-4">
        <strong>Pills</strong>
      </h5>
      <div class="mb-5">
        <b-badge pill class="mr-2" variant="primary">Primary</b-badge>
        <b-badge pill class="mr-2" variant="secondary">Secondary</b-badge>
        <b-badge pill class="mr-2" variant="success">Success</b-badge>
        <b-badge pill class="mr-2" variant="danger">Danger</b-badge>
        <b-badge pill class="mr-2" variant="warning">Warning</b-badge>
        <b-badge pill class="mr-2" variant="info">Info</b-badge>
        <b-badge pill class="mr-2" variant="light">Light</b-badge>
        <b-badge pill class="mr-2" variant="dark">Dark</b-badge>
      </div>
    </div>
    <div class="col-lg-6">
      <h5 class="mb-4">
        <strong>Links</strong>
      </h5>
      <div class="mb-5">
        <b-badge href="#" class="mr-2" variant="primary">Primary</b-badge>
        <b-badge href="#" class="mr-2" variant="secondary">Secondary</b-badge>
        <b-badge href="#" class="mr-2" variant="success">Success</b-badge>
        <b-badge href="#" class="mr-2" variant="danger">Danger</b-badge>
        <b-badge href="#" class="mr-2" variant="warning">Warning</b-badge>
        <b-badge href="#" class="mr-2" variant="info">Info</b-badge>
        <b-badge href="#" class="mr-2" variant="light">Light</b-badge>
        <b-badge href="#" class="mr-2" variant="dark">Dark</b-badge>
      </div>
    </div>
  </div>
  <!-- <div>
    <div class="mb-3">
      <b-badge class="mr-2" variant="primary">Primary</b-badge>
      <b-badge class="mr-2" variant="secondary">Secondary</b-badge>
      <b-badge class="mr-2" variant="success">Success</b-badge>
      <b-badge class="mr-2" variant="danger">Danger</b-badge>
      <b-badge class="mr-2" variant="warning">Warning</b-badge>
      <b-badge class="mr-2" variant="info">Info</b-badge>
      <b-badge class="mr-2" variant="light">Light</b-badge>
      <b-badge class="mr-2" variant="dark">Dark</b-badge>
    </div>
    <div>
      <b-badge class="mr-2" pill variant="primary">Primary</b-badge>
      <b-badge class="mr-2" pill variant="secondary">Secondary</b-badge>
      <b-badge class="mr-2" pill variant="success">Success</b-badge>
      <b-badge class="mr-2" pill variant="danger">Danger</b-badge>
      <b-badge class="mr-2" pill variant="warning">Warning</b-badge>
      <b-badge class="mr-2" pill variant="info">Info</b-badge>
      <b-badge class="mr-2" pill variant="light">Light</b-badge>
      <b-badge class="mr-2" pill variant="dark">Dark</b-badge>
    </div>
  </div>-->
</template>
<script>
export default {
  name: 'KitBootstrapBadgesExample',
}
</script>
