<template>
  <div>
    <h5 class="mb-4">
      <strong>Default Usage</strong>
    </h5>
    <div class="mb-5">
      <b-form-checkbox>I accept the terms and use</b-form-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitBootstrapFormCheckboxExample',
  data() {
    return {
    }
  },
}
</script>
